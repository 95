.skills {
  padding: 3rem 10%;
  max-width: 1440px;
  width: 100%;

  // Add responsive styles for smaller devices
  @include tablet() {
    padding: 2rem 50px;
  }

  @include mobile() {
    padding: 1rem 30px;
  }

  &__title {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;

    @include tablet() {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mobile() {
      grid-template-columns: repeat(1, 1fr);
      gap: 2rem;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $color-primary;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
    height: 11rem;

    &:hover {
      .skills__overlay {
        @include wipeTopLeft(0.3s);
      }
    }
  }

  &__button {
    background-color: $color-primary; // Use the appropriate color variable for your design
    color: #fff;
    border: none;
    border-radius: 0.5rem;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    margin-top: 2rem;
    transition: background-color 0.3s;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      &::after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background-color: $color-warm-white;
        transition: width 0.3s;
      }
    }

    &:hover {
      background-color: $color-primary-50; // Use the appropriate color variable for your design
      span {
        // animate a scrolling underline on hover
        &::after {
          width: 100%;
        }
      }
    }

    @include tablet() {
      font-size: 1.3rem;
    }
  }

  &__icon {
    height: 100%;
    width: 100%;

    svg {
      height: 100%;
      width: 100%;
      padding: 2rem;
    }
  }

  &__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $color-primary-dark-60;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    justify-content: center;
    padding: 2rem;
    gap: 1rem;
  }

  &__name {
    font-size: 2rem;
    pointer-events: none;
  }

  &__description {
    font-size: 1.5rem;
    pointer-events: none;
  }
}
