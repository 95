// Cinzel Decorative
@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap");

// Monaco
@font-face {
  font-family: "Monaco";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: "../../assets/fonts/Monaco.ttf";
}
