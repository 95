.navbar {
  display: flex;
  width: 100vw;
  height: $navbar-height;
  z-index: 10;
  position: fixed;
  transition: background-color 0.3s ease-out;

  &.overlaping {
    background-color: $color-primary-dark-80;
  }

  .logo {
    padding: 5px 10px;
    font-size: 20px;
    cursor: default;
  }

  .nav-menu {
    display: none;
    margin-left: auto;

    @include desktop {
      display: flex;
    }
  }
}
