// Define breakpoints
$breakpoint-mobile: 425px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

// Mobile breakpoint mixin
@mixin mobile {
  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}

// Tablet breakpoint mixin
@mixin tablet {
  @media (min-width: ($breakpoint-mobile + 1)) and (max-width: $breakpoint-tablet) {
    @content;
  }
}

// Desktop breakpoint mixin
@mixin desktop {
  @media (min-width: ($breakpoint-tablet + 1)) {
    @content;
  }
}
