* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Monaco", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: $color-primary;
  color: $color-warm-white;
}

.App {
  min-height: 100vh;
  min-width: 100vw;
  cursor: default;
  max-width: 100vw;
  overflow: hidden;
}

a,
button {
  text-decoration: none;
  color: inherit;
  background: none;
  transition: 0.3s;

  // animate a scrolling underline on hover
  &:hover {
    &::after {
      width: 100%;
    }
  }

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background-color: $color-warm-white;
    transition: width 0.3s;
  }
}

li {
  list-style-type: none;
  transition: 0.3s;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
a,
button,
input,
textarea {
  margin: 0;
  padding: 0;
  font-weight: 400;
}

button {
  border: none;
  cursor: pointer;
  font-family: inherit;
  text-decoration: none;
  color: inherit;
  transition: 0.3s;
  font-size: inherit;
}

input,
textarea {
  border: none;
  outline: none;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: $color-primary-dark;
  background-color: $color-primary-light;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  transition: 0.3s;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus {
  background-color: $color-warm-white;
}

input[type="submit"] {
  background-color: $color-secondary;
  color: $color-warm-white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  transition: 0.3s;
  cursor: pointer;
}
