@mixin mobileAndTabletNavStyles {
  padding: 3rem 3rem 6rem 3rem;
  box-shadow: 5px 10px 10px 0px $color-warm-black-20;
  background-color: $color-primary-dark-90;
  display: flex;
  flex-direction: column;
  gap: 10px;
  top: -$navbar-height;
  position: relative;
  @include wipeTopRight(0.3s);
	width: $mobile-menu-width;
	margin-left: auto;
	pointer-events: auto;

  .mobile-menu-item {
    text-align: center;

    &:hover {
      filter: drop-shadow(0px 0px 5px #000);
    }
  }
}

.nav-menu-links {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-right: 20px;

  @include tablet {
    @include mobileAndTabletNavStyles;
  }

  @include mobile {
    @include mobileAndTabletNavStyles;
  }
}
