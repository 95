.footer {
  color: $color-warm-white;
  padding: 2rem 10%;
  width: 100%;
  background-color: $color-primary-dark-80;

  &__content {
    text-align: center;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__privacy-policy {
    width: fit-content;
    margin: auto;

    &::after {
      height: 1px;
    }
  }
}

// When on the home page, make it fully dark to match the rest of the styles
.home + .footer {
  background-color: $color-primary-dark;
}
