// hero section for home page
.hero {
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, $color-primary-dark-0 0%, $color-primary-dark-50 100%);
  background-color: $color-primary;
  position: relative;

  // create geometric cut out at bottom
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);

  // center contents
  display: flex;
  justify-content: center;

  // a partially transparent shadow gradient at the bottom of the hero to make it look like its fading into the background
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    // center the gradient horizontally to the screen
    left: 50%;
    transform: translateX(-50%);
    // make the gradient 100% of the screen width and 20% of the screen height
    width: 100vw;
    height: 20%;
    background: linear-gradient(180deg, $color-primary-dark-0 0%, $color-primary-dark-50 100%);
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 1440px;
    width: 100%;
    justify-items: center;
    transition: all 0.3s;
    position: relative;
    height: 100%;
    overflow: hidden;

    @include tablet() {
      max-width: 100vw;
      grid-template-columns: repeat(1, 1fr);
    }

    @include mobile() {
      max-width: 100vw;
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__text {
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    padding-bottom: 20%;
    padding-left: 10%;
    cursor: default;

    @include tablet() {
      position: absolute;
      top: 15%;
      left: 5%;
      padding-left: 0;
      padding-bottom: 0%;
    }
    @include mobile() {
      position: absolute;
      top: 5%;
      left: 5%;
      padding-left: 0;
      padding-bottom: 0%;
    }
  }

  &__greeting {
    color: $color-primary-light;
    font-size: 2.6rem;
    margin-left: 5px;
  }

  &__name {
    font-size: 6.8rem;
    font-family: "Cinzel Decorative";
    text-shadow: 5px 5px 10px $color-warm-black-20;

    @include tablet() {
      max-width: 95%;
      font-size: 6rem;
    }

    @include mobile() {
      max-width: 95%;
      font-size: 5rem;
    }
  }

  &__title {
    font-size: 1.8rem;
    margin-left: 5px;
    height: 2.7rem;
  }

  &__image {
    transition: all 0.3s;
    height: calc(90vh - 40px);
    z-index: -1;
    margin-top: auto;
    margin-left: auto;
    position: relative;
    opacity: 0;

    @include slideLeftFadeIn(-5rem, 2.5s);

    @include tablet() {
      height: 100%;
      max-height: 60vh;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    @include mobile() {
      height: 100%;
      max-height: 60vh;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    img {
      height: 100%;
      filter: opacity(0.95) drop-shadow(5px 5px 10px $color-warm-black-50);
    }
  }

  &__background-image {
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    filter: grayscale(100%);

    img {
      width: 100%;
      height: 100%;
      opacity: 0.2;
      object-fit: cover;
    }
  }
}
