.contact-me {
  padding: 5rem 10% 3rem;
  color: #ffffff;
  text-align: justify;
  max-width: 1440px;
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
	margin: auto;

  // Add responsive styles for smaller devices
  @include tablet() {
    padding: 5rem 5% 2rem;
  }

  @include mobile() {
    padding: 5rem 2% 1rem;
  }

  &__title {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  &__description {
    margin-bottom: 3rem;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;

    li {
      display: flex;
      gap: 1rem;
    }

    a {
      width: fit-content;

      // hiding overflow text
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }

  &__form {
    &__buttons {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 3rem;

      button,
      a {
        width: fit-content;
      }

      &__submit-button {
        display: flex;
        gap: 1rem;
      }
    }
  }
}
