.companies {
  width: 100%;
  padding: 3rem 10%;
  max-width: 1440px;

  // Add responsive styles for smaller devices
  @include tablet() {
    padding: 2rem 50px;
  }

  @include mobile() {
    padding: 1rem 30px;
  }

  &__title {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  &__item {
    flex: 0 0 calc((100% - 6rem) / 7);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3;

    @include tablet() {
      flex: 0 0 calc((100% - 3rem) / 4);
    }

    @include mobile() {
      flex: 0 0 calc((100% - 2rem) / 3);
    }

    &__logo {
      width: 100%;
      height: auto;
      object-fit: contain;
      transition: all 0.3s ease;
      background-color: $color-primary-light;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;

      &__image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        opacity: 0.4;
        // grayscale is set on the component itself using a filter attribute defined in the app's head with a svg def matrix.
      }
    }
  }
}
