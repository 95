// Base Color Pallete
$color-primary: #648399;
$color-primary-0: #64839900;
$color-primary-10: #6483991a;
$color-primary-20: #64839933;
$color-primary-30: #6483994d;
$color-primary-40: #64839966;
$color-primary-50: #64839980;
$color-primary-60: #64839999;
$color-primary-70: #648399b3;
$color-primary-80: #648399cc;
$color-primary-90: #648399e6;
$color-primary-100: #648399ff;

$color-primary-dark: #2a3e4d;
$color-primary-dark-0: #2a3e4d00;
$color-primary-dark-10: #2a3e4d1a;
$color-primary-dark-20: #2a3e4d33;
$color-primary-dark-30: #2a3e4d4d;
$color-primary-dark-40: #2a3e4d66;
$color-primary-dark-50: #2a3e4d80;
$color-primary-dark-60: #2a3e4d99;
$color-primary-dark-70: #2a3e4db3;
$color-primary-dark-80: #2a3e4dcc;
$color-primary-dark-90: #2a3e4de6;
$color-primary-dark-100: #2a3e4dff;

$color-primary-light: #accee5;
$color-primary-light-0: #accee500;
$color-primary-light-10: #accee51a;
$color-primary-light-20: #accee533;
$color-primary-light-30: #accee54d;
$color-primary-light-40: #accee566;
$color-primary-light-50: #accee580;
$color-primary-light-60: #accee599;
$color-primary-light-70: #accee5b3;
$color-primary-light-80: #accee5cc;
$color-primary-light-90: #accee5e6;
$color-primary-light-100: #accee5ff;

$color-secondary: #4d3b22;
$color-secondary-0: #4d3b2200;
$color-secondary-10: #4d3b221a;
$color-secondary-20: #4d3b2233;
$color-secondary-30: #4d3b224d;
$color-secondary-40: #4d3b2266;
$color-secondary-50: #4d3b2280;
$color-secondary-60: #4d3b2299;
$color-secondary-70: #4d3b22b3;
$color-secondary-80: #4d3b22cc;
$color-secondary-90: #4d3b22e6;
$color-secondary-100: #4d3b22ff;

$color-tertiary: #998363;
$color-tertiary-0: #99836300;
$color-tertiary-10: #9983631a;
$color-tertiary-20: #99836333;
$color-tertiary-30: #9983634d;
$color-tertiary-40: #99836366;
$color-tertiary-50: #99836380;
$color-tertiary-60: #99836399;
$color-tertiary-70: #998363b3;
$color-tertiary-80: #998363cc;
$color-tertiary-90: #998363e6;
$color-tertiary-100: #998363ff;

// White
$color-white: #ffffff;
$color-white-0: #ffffff00;
$color-white-10: #ffffff1a;
$color-white-20: #ffffff33;
$color-white-30: #ffffff4d;
$color-white-40: #ffffff66;
$color-white-50: #ffffff80;
$color-white-60: #ffffff99;
$color-white-70: #ffffffb3;
$color-white-80: #ffffffcc;
$color-white-90: #ffffffe6;
$color-white-100: #ffffffff;

$color-warm-white: #f2efe4;
$color-warm-white-0: #f2efe400;
$color-warm-white-10: #f2efe41a;
$color-warm-white-20: #f2efe433;
$color-warm-white-30: #f2efe44d;
$color-warm-white-40: #f2efe466;
$color-warm-white-50: #f2efe480;
$color-warm-white-60: #f2efe499;
$color-warm-white-70: #f2efe4b3;
$color-warm-white-80: #f2efe4cc;
$color-warm-white-90: #f2efe4e6;
$color-warm-white-100: #f2efe4ff;

// Black
$color-black: #000000;
$color-black-0: #00000000;
$color-black-10: #0000001a;
$color-black-20: #00000033;
$color-black-30: #0000004d;
$color-black-40: #00000066;
$color-black-50: #00000080;
$color-black-60: #00000099;
$color-black-70: #000000b3;
$color-black-80: #000000cc;
$color-black-90: #000000e6;
$color-black-100: #000000ff;

$color-warm-black: #1a1a1a;
$color-warm-black-0: #1a1a1a00;
$color-warm-black-10: #1a1a1a1a;
$color-warm-black-20: #1a1a1a33;
$color-warm-black-30: #1a1a1a4d;
$color-warm-black-40: #1a1a1a66;
$color-warm-black-50: #1a1a1a80;
$color-warm-black-60: #1a1a1a99;
$color-warm-black-70: #1a1a1ab3;
$color-warm-black-80: #1a1a1acc;
$color-warm-black-90: #1a1a1ae6;
$color-warm-black-100: #1a1a1aff;
